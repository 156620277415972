import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '150px',
    },
  },
});

function MiniHero(props) {
  const { children, withSearch, classes } = props;
  const paddingY = withSearch ? 45 : 30;
  return (
    <Box pt={paddingY} position="relative" className={classes.wrapper}>
      {children}
    </Box>
  );
}

MiniHero.propTypes = {
  children: PropTypes.node,
  withSearch: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MiniHero);
