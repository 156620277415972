import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Logo from 'images/Hayfield-Wordmark-Reverse.svg';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from 'gatsby-link';
import linkResolver from 'utils/linkResolver';
import { MicNone, MicNoneTwoTone } from '@material-ui/icons';

const K_WIDTH = 40;
const K_HEIGHT = 40;

const styles = theme => ({
  marker: {
    position: 'absolute',
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    color: theme.palette.text.white,
    fontSize: 16,
    fontWeight: 'bold',
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& .info--box': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  arrow: {
    position: 'absolute',
    zIndex: -1,
    width: '0px',
    height: '0px',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderTop: `20px solid ${theme.palette.primary.main}`,
    bottom: '-6px',
  },
  info: {
    position: 'absolute',
    background: '#1f1b37',
    bottom: '0px',
    zIndex: '99',
    opacity: '0',
    visibility: 'hidden',
    transition: 'all 0.3s ease-in-out',
  },
  ident: {
    height: '6px',
  },
  linkWrapper: {
    textDecoration: 'none',
    padding: '30px 50px',
    '& p': {
      textDecoration: 'none',
      color: theme.palette.text.white,
    },
  },
});

function Map(props) {
  const { data, classes } = props;
  const defaultProps = {
    center: {
      lat: 52.0381331,
      lng: -0.7926635,
    },
    zoom: 9,
  };

  const bedroomRange = data => {
    let array = [];
    if (data && data[0] && data[0].home_type && data.length > 1) {
      data.map(item => {
        if (item.home_type && item.home_type.number_of_bedrooms) {
          array.push(item.home_type.number_of_bedrooms);
        }
      });
    }
    const unique = array.filter((item, index) => array.indexOf(item) === index);
    if (unique.length > 1) {
      return `${Math.min(...unique)} - ${Math.max(...unique)} beds`;
    }
    return '';
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          styles: [
            {
              elementType: 'geometry',
              stylers: [
                {
                  color: '#f5f5f5',
                },
              ],
            },
            {
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#e1e6ea',
                },
              ],
            },
            {
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#000000',
                },
              ],
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              elementType: 'labels.text',
              stylers: [
                {
                  color: '#f40606',
                },
              ],
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#1f1b37',
                },
              ],
            },
            {
              elementType: 'labels.text.stroke',
              stylers: [
                {
                  color: '#f5f5f5',
                },
              ],
            },
          ],
        }}
      >
        {data.map((item, key) => {
          return (
            <div
              key={key}
              lat={item.node.geolocation.latitude}
              lng={item.node.geolocation.longitude}
              className={classes.marker}
            >
              H
              <div className={classes.arrow} />
              <Box
                className={`${classes.info} info--box`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Link
                  to={linkResolver(item.node._meta)}
                  className={classes.linkWrapper}
                >
                  <Box mb={1}>
                    <Logo className={classes.ident} />
                  </Box>
                  <Typography component="p" variant="h6">
                    {item.node.title[0].text}
                  </Typography>
                  <Typography component="p" variant="caption">
                    {item.node.address[0].town[0].text}
                  </Typography>
                  <Typography component="p" variant="caption">
                    {bedroomRange(item.node.available_house_types)}
                  </Typography>
                </Link>
              </Box>
            </div>
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

Map.propTypes = {
  data: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Map);
