import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Logo from 'images/Hayfield-Wordmark-Reverse.svg';
import ImageSlider from 'components/slices/ImageSlider';
import { withStyles } from '@material-ui/styles';
import linkResolver from 'utils/linkResolver';
import { Button } from 'components/ui';

const styles = theme => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  logo: {
    width: '60px',
    [theme.breakpoints.up('lg')]: {
      width: '80px',
    },
  },
});

function ResultCard(props) {
  const { data, index, classes } = props;
  const [beds, setBeds] = useState([]);
  const [pricefrom, setPricefrom] = useState([]);
  useEffect(() => {
    if (data.node.available_house_types.length > 0) {
      const array = [];
      const pricearray = [];
      data.node.available_house_types.map(item => {
        if (item.home_type) {
          if (item.home_type.number_of_bedrooms) {
            array.push(item.home_type.number_of_bedrooms);
          }
          if (item.home_type.price_from) {
            pricearray.push(item.home_type.price_from);
          }
        }
      });
      const unique = array.filter(
        (item, index) => array.indexOf(item) === index
      );
      setBeds(unique);
      //const min = Math.min.apply(Math, pricearray);
      setPricefrom(pricearray);
    }
  }, [data]);
  return (
    <Box bgcolor="primary.main" height="100%" mb={4}>
      <Grid
        container
        display="flex"
        direction={`${index % 2 == 0 ? 'row-reverse' : 'row'}`}
      >
        <Grid item xs={12} md={6} lg={8}>
          {data.node.search_result_images && (
            <ImageSlider data={data.node.search_result_images} />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box
            height="100%"
            display={{ xs: 'block', md: 'flex' }}
            alignItems="center"
            justifyContent="space-evenly"
            flexDirection="column"
            p={4}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              flex={{ xs: '1 1 0px', md: '' }}
            >
              <Logo className={classes.logo} />
              <Box py={2}>
                <Typography component="p" variant="h4" color="secondary">
                  {data.node.title[0].text}
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="subtitle2"
                color="secondary"
                gutterBottom
                className={classes.uppercase}
              >
                {data.node.address[0].town[0].text}
              </Typography>
              <Typography
                component="p"
                variant="subtitle2"
                color="secondary"
                className={classes.uppercase}
              >
                {data.node.address[0].postcode[0].text}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flex={{ xs: '1 1 0px', md: '' }}
            >
              {data.node.availability && data.node.availability[0] && (
                <Typography
                  component="p"
                  variant="subtitle1"
                  color="secondary"
                  align="center"
                >
                  {data.node.availability[0].text}
                </Typography>
              )}
              {beds && beds.length > 0 && (
                <Box mt={2}>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color="secondary"
                    align="center"
                  >
                    {beds.map((item, i, arr) => {
                      return (
                        <React.Fragment key={i}>
                          {item}
                          {arr.length - 1 === i ? '' : ', '}
                        </React.Fragment>
                      );
                    })}{' '}
                    BEDROOMS
                  </Typography>
                </Box>
              )}
              {pricefrom && pricefrom != '' && (
                <Box>
                  <Typography
                    component="p"
                    variant="subtitle1"
                    color="secondary"
                    align="center"
                  >
                    Prices from £
                    {parseInt(Math.min.apply(Math, pricefrom)).toLocaleString()}
                  </Typography>
                </Box>
              )}
              <Box mt={{ xs: 2, md: 'auto' }}>
                <Button text="Explore" link={linkResolver(data.node._meta)} />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ResultCard.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResultCard);
