import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'store/provider';
import { graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Layout from 'components/layout';
import MiniHero from 'components/hero/miniHero';
import searchList from 'helpers/searchList';
import SearchBar from 'components/search';
import TotalResults from 'components/searchresults/TotalResults';
import ResultCard from 'components/searchresults/ResultCard';
import getLocationOptions from 'helpers/getLocationOptions';
import Map from 'components/searchresults/Map';
import { MapToggleBtn } from 'components/ui';
import { withStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from 'react-helmet';
import Filters from 'components/search/FilterActions';

const styles = theme => ({
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100vh',
    top: 0,
    left: 0,
    background: 'rgba(31, 50, 65, 0.8)',
    zIndex: 110,
  },
  locationOptions: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'white',
    zIndex: 101,
    padding: '30px',
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      height: '500px',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      padding: '50px',
    },
  },
  selectBtn: {
    width: '100%',
    textAlign: 'left',
    padding: '10px',
    background: 'transparent',
    border: '1px solid black',
    boxShadow: 'none',
    cursor: 'pointer',
    marginBottom: '4px',
    transition: 'background-color 0.3s ease-in-out',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.white,
    },
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
});

function Search(props) {
  const { data, classes } = props;
  const doc = props.data.prismic.allSearch_pages.edges.slice(0, 1).pop();
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [showlist, setShowlist] = useState(false);
  const [showmap, setShowmap] = useState(false);
  const [update, setUpdate] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [doLocationLookup, setDoLocationLookup] = useState(true);
  const [updatedToYourLocation, setUpdatedToYourLocation] = useState(false);
  const [locationUpdateAvailable, setLocationUpdateAvailable] = useState(false);
  //const [noexactresults, setNoexactresults] = useState(false);

  async function filterList(getAll) {
    const results = await searchList(
      data.prismic.allDevelopments.edges,
      state,
      getAll
    );
    setFilteredList(results);
    setLoading(false);
    setShowlist(true);
  }

  const chooseLocation = async item => {
    dispatch({
      ...state,
      type: 'SET_SEARCH',
      geometry: item.geometry,
      location: item.formatted,
    });
    dispatch({ ...state, type: 'SET_BODY_FIXED', body_fixed: false });
    setLocationsList([]);
    const searchValues = {
      geometry: item.geometry,
      houseTypes: state.houseTypes,
      bedrooms: state.bedrooms,
      minprice: state.minprice,
      radius: state.radius,
    };
    const results = await searchList(
      data.prismic.allDevelopments.edges,
      searchValues,
      false
    );
    setFilteredList(results);
    setLoading(false);
    setShowlist(true);
    setDoLocationLookup(false);
  };

  const updateResults = position => {
    if (position || state.location == 'YOUR LOCATION') {
      setUpdatedToYourLocation(true);
    }
    setUpdate(true);
    setLocationUpdateAvailable(false);
    //setNoexactresults(false);
  };

  const checkIfLocationIsChanging = value => {
    if (value != state.location) {
      setLocationUpdateAvailable(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    setUpdate(false);
    setUpdatedToYourLocation(false);
    async function fetchData() {
      if (doLocationLookup === true) {
        const resultLocations = await getLocationOptions(state.location);
        if (resultLocations.data.results.length > 1) {
          setLocationsList(resultLocations.data.results);
          dispatch({ ...state, type: 'SET_BODY_FIXED', body_fixed: true });
          setLoading(false);
        } else if (resultLocations.data.results.length == 1) {
          dispatch({ ...state, type: 'SET_BODY_FIXED', body_fixed: false });
          dispatch({
            ...state,
            type: 'SET_SEARCH',
            geometry: resultLocations.data.results[0].geometry,
          });
          filterList(false);
        }
      } else {
        filterList(false);
      }
    }
    if (
      state.location &&
      state.your_location.length == 0 &&
      !updatedToYourLocation
    ) {
      fetchData();
    } else if (state.your_location.length > 0 || updatedToYourLocation) {
      setLoading(false);
      filterList();
    } else {
      setLoading(false);
      filterList(true);
    }
  }, [update]);

  useEffect(() => {
    setDoLocationLookup(true);
    dispatch({
      ...state,
      type: 'SET_SEARCH',
      your_location: [],
    });
  }, [state.location]);

  /*
  useEffect(() => {
    if (filteredList.length == 0 && state.location) {
      setNoexactresults(true);
      filterList(true);
    }
  }, [filteredList]);
  */

  const Loading = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          position: 'fixed',
          zIndex: 50,
          width: '100%',
          height: '100%',
          background: '#fff',
          opacity: 0.8,
          top: 0,
          left: 0,
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  const LocationSelect = props => {
    const { list, classes } = props;
    return (
      <React.Fragment>
        <Box className={classes.overlay}>
          <Box className={classes.locationOptions}>
            <Box mb={4}>
              <Typography component="h4" variant="h5">
                Select location
              </Typography>
            </Box>
            {list.map((item, key) => (
              <div key={key}>
                <button
                  aria-label="Choose location"
                  onClick={() => chooseLocation(item)}
                  className={classes.selectBtn}
                >
                  <Typography component="p" variant="button">
                    {item.formatted}
                  </Typography>
                </button>
              </div>
            ))}
          </Box>
        </Box>
      </React.Fragment>
    );
  };

  LocationSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    list: PropTypes.array,
  };

  const toggleView = React.useCallback(() => setShowmap(state => !state), [
    setShowmap,
  ]);

  return (
    <Layout themeColor="white">
      {doc.node.metadata && doc.node.metadata[0] && (
        <Helmet>
          {doc.node.metadata[0].title && doc.node.metadata[0].title[0] && (
            <title>{doc.node.metadata[0].title[0].text}</title>
          )}
          {doc.node.metadata[0].description &&
            doc.node.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.node.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.node.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.node.metadata[0].keywords &&
            doc.node.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.node.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      {loading && <Loading />}
      {locationsList.length > 0 && (
        <LocationSelect list={locationsList} classes={classes} />
      )}
      <MiniHero withSearch>
        <SearchBar
          updateSearch
          updateResults={updateResults}
          checkIfLocationIsChanging={checkIfLocationIsChanging}
          locationUpdateAvailable={locationUpdateAvailable}
        />
      </MiniHero>
      <Container maxWidth="xl">
        <Box
          component={Grid}
          container
          py={6}
          mb={6}
          className={classes.borderBottom}
        >
          <Box component={Grid} item xs={12} mb={4}>
            <TotalResults
              data={filteredList}
              searchedLocation={state.location}
              searchedRadius={state.radius}
            />
          </Box>
          <Box display="flex" component={Grid} item xs={12} lg={6}>
            <Filters updateResults={updateResults} />
          </Box>
          {filteredList.length > 0 && (
            <Box
              component={Grid}
              item
              xs={12}
              lg={6}
              display="flex"
              alignItems="flex-end"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              mt={[4, 0, 0, 0]}
            >
              {!showmap ? (
                <MapToggleBtn
                  ClickFunction={toggleView}
                  text="Map View"
                  icon="map"
                />
              ) : (
                <MapToggleBtn
                  ClickFunction={toggleView}
                  text="List View"
                  icon="list"
                />
              )}
            </Box>
          )}
        </Box>
        {!showmap && showlist && filteredList.length > 0 && (
          <React.Fragment>
            {filteredList.map((item, key) => (
              <ResultCard key={key} data={item} index={key} />
            ))}
          </React.Fragment>
        )}
        {showmap && <Map data={filteredList} />}
      </Container>
    </Layout>
  );
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export const query = graphql`
  query {
    prismic {
      allDevelopments {
        edges {
          node {
            title
            availability
            geolocation
            development_status
            address {
              town
              postcode
            }
            available_house_types {
              home_type {
                ... on PRISMIC_Home_type {
                  price_from
                  number_of_bedrooms
                  house_types {
                    house_type
                  }
                }
              }
            }
            search_result_images {
              image
            }
            _meta {
              uid
              type
              id
            }
            metadata {
              title
              keywords
              description
            }
          }
        }
      }
      allSearch_pages {
        edges {
          node {
            metadata {
              title
              keywords
              description
            }
          }
        }
      }
    }
  }
`;

export default withStyles(styles)(Search);
