import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

function TotalResults(props) {
  const { data, searchedLocation, searchedRadius } = props;
  var totalproperties = 0;
  data.map(item => {
    item.node.available_house_types.map(house => {
      if (house.home_type) {
        totalproperties++;
      }
    });
  });

  return (
    <Box>
      <Box mb={4}>
        <Typography component="p" variant="subtitle1">
          {data.length} Developments, {totalproperties}{' '}
          {totalproperties > 1 ? 'Properties' : 'Property'}
        </Typography>
      </Box>
      {searchedLocation && (
        <Box>
          <Typography component="p" variant="subtitle1">
            Our homes{' '}
            {searchedRadius > 0
              ? `within ${searchedRadius} miles of`
              : 'Nationwide'}
          </Typography>
          {searchedLocation && searchedRadius > 0 && (
            <Typography component="h3" variant="h3">
              {searchedLocation}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

TotalResults.propTypes = {
  data: PropTypes.array.isRequired,
  searchedLocation: PropTypes.string,
  searchedRadius: PropTypes.string,
};

export default TotalResults;
