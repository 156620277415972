import distance from './geoDistance';

const searchList = (data, searchValues, getAll = false) => {
  var list = Object.values(data).filter(item => {
    item['distance'] = 0;
    if (getAll === true && searchValues.houseTypes.length == 0) {
      return true;
    }

    // node.development_status

    if (item.node.development_status === false) {
      //return false;
    }

    var includeItem = false;
    if (searchValues.geometry.lat && searchValues.geometry.lng) {
      // So this is not a search based on a house development name
      // Find distance between search location and development in this iteration
      const distance_between = distance(
        searchValues.geometry.lat,
        searchValues.geometry.lng,
        item.node.geolocation.latitude,
        item.node.geolocation.longitude
      );
      item['distance'] = distance_between;
      if (searchValues.radius == 0 || distance_between <= searchValues.radius) {
        // is within search radius so include (for now) subject to next check on search items
        includeItem = true;
      }
    } else {
      includeItem = true;
    }

    if (includeItem === true) {
      // only check other search critera if development is within search radius
      if (
        searchValues.bedrooms > 0 ||
        searchValues.minprice > 0 ||
        (searchValues.houseTypes && searchValues.houseTypes.length > 0) ||
        (searchValues.numOfBedsRange && searchValues.numOfBedsRange.length > 0)
      ) {
        // Look at each house type in the development to see if they meet the other search criteria
        var houseTypeMatch = false;
        var countHomeTypes = 0;
        var houseVariantMatch = false;

        item.node.available_house_types.map(house => {
          if (house.home_type) {
            countHomeTypes++;
            if (
              house.home_type.number_of_bedrooms >= searchValues.bedrooms &&
              house.home_type.price_from > searchValues.minprice
            ) {
              houseTypeMatch = true;
            }

            if (searchValues.houseTypes.length > 0) {
              // check advanced filter house variations E.G Detatched
              if (
                house.home_type.house_types &&
                house.home_type.house_types.length > 0
              ) {
                house.home_type.house_types.map(type => {
                  if (searchValues.houseTypes.includes(type.house_type)) {
                    houseVariantMatch = true;
                  }
                });
              }
            }
          }
        });
        if (houseTypeMatch === false || countHomeTypes == 0) {
          includeItem = false;
        }
        if (searchValues.houseTypes.length > 0 && houseVariantMatch === false) {
          includeItem = false;
        }
      }
    }
    return includeItem;
  });

  if (searchValues.priceRange && searchValues.priceRange.length > 0) {
    list = Object.values(list).filter(item => {
      var includeItem = false;
      item.node.available_house_types.map(house => {
        if (house.home_type && house.home_type.price_from) {
          if (
            house.home_type.price_from >= searchValues.priceRange[0] &&
            house.home_type.price_from <= searchValues.priceRange[1]
          ) {
            includeItem = true;
          }
        }
      });
      return includeItem;
    });
  }

  if (searchValues.numOfBedsRange && searchValues.numOfBedsRange.length > 0) {
    list = Object.values(list).filter(item => {
      var includeItem = false;
      item.node.available_house_types.map(house => {
        if (house.home_type && house.home_type.number_of_bedrooms) {
          if (
            house.home_type.number_of_bedrooms >=
              searchValues.numOfBedsRange[0] &&
            house.home_type.number_of_bedrooms <= searchValues.numOfBedsRange[1]
          ) {
            includeItem = true;
          }
        }
      });
      return includeItem;
    });
  }
  list.sort((a, b) => a.distance - b.distance);

  const falseStatusItems = list.filter(item => !item.node.development_status);
  const trueStatusItems = list.filter(item => item.node.development_status);

  const sortedList = [...trueStatusItems, ...falseStatusItems];

  console.log(sortedList);
  return sortedList;
};

export default searchList;
